import React from "react"

import Project from "../Project"

const ProjectsList = ({ projects }) => (
  <section>
    {projects.map(({ node }, i) => (
      <Project
        leftProject={!(i % 2)}
        reducePadding={i + 1 === projects.length}
        node={node}
        key={node.slug}
      />
    ))}
  </section>
)

export default ProjectsList
