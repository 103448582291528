import React from "react"
import { StaticQuery, graphql } from "gatsby"
import classnames from "classnames"

import * as skills from "./skills.module.scss"
import Container from "../Container"
import Row from "../Row"
import ToolsUsed from "../ToolsUsed"
import { H2 } from "../Typography"

const Skills = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulPerson(name: { eq: "Michael" }) {
          tools
        }
      }
    `}
    render={data => (
      <section className={skills.section}>
        <Container className={skills.container}>
          <Row className={skills.row} noMargin>
            <H2
              className={classnames(skills.skills_header)}
              text={"A Few of the Tools I Use"}
            />
            <ToolsUsed tools={data.contentfulPerson.tools} column />
          </Row>
        </Container>
      </section>
    )}
  />
)

export default Skills
