const logosFormatted = (logosUnformatted, toolsStr) => {
  let urlArray = []

  // Split toolsStr by comma, then trim and set to lowercase.
  // Washes input to increase number of possible inputs. ('react' == 'React  ')
  toolsStr.split(",").forEach((toolElem) => {
    const tool = toolElem.trim().toLowerCase()

    // Look for match in logosUnformatted
    logosUnformatted.forEach((logoElem) => {
      if (logoElem.node.title.toLowerCase() === tool) {
        urlArray.push({
          alt: tool.replace(/\b\w/g, (l) => l.toUpperCase()), // Capitalize first letters of each word
          url: logoElem.node.image.file.url,
          href: logoElem.node.href,
          title: logoElem.node.title,
        })
        return
      }
    })
  })

  // format = [ {alt: ___, url: ___, href: ___}, ... ]
  return urlArray
}

export default logosFormatted
