import React from "react"
import classnames from "classnames"
import axios from "axios"

import * as contact from "./contact.module.scss"
import Container from "../Container"
import Row from "../Row"
import { H1, H4 } from "../Typography"

const API_PATH = "https://www.michaelrae.org/mailbot.php"

class Contact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      message: "",
      mailSent: false,
      error: null,
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: {
        "content-type": "application/json",
      },
      data: this.state,
    })
      .then((result) => {
        this.setState({
          mailSent: result.data.sent,
        })
      })
      .catch((error) => this.setState({ error: error.message }))
  }

  render() {
    return (
      <section className={contact.section}>
        <Container className={contact.container}>
          <Row className={contact.row__header} noMargin>
            <H1 className={contact.header__title} text={"Contact"} />
            <div className={contact.header__bar} />
            <H4
              className={contact.header__subtitle}
              text={"Have a question or want to work together?"}
            />
          </Row>

          <Row className={contact.row__form} noMargin>
            <form
              action=""
              method="POST"
              className={contact.form}
              onSubmit={this.submitForm}
            >
              <label htmlFor="name" style={{ display: "none" }}>
                Name
              </label>
              <input
                placeholder="Name"
                type="text"
                name="name"
                id="name"
                required
                className={contact.form__info}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              <label htmlFor="email" style={{ display: "none" }}>
                Email
              </label>
              <input
                placeholder="Enter Email"
                type="email"
                name="email"
                id="email"
                required
                className={contact.form__info}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <label htmlFor="message" style={{ display: "none" }}>
                Message
              </label>
              <textarea
                placeholder="Your Message"
                type="text"
                name="message"
                id="message"
                className={classnames(
                  contact.form__info,
                  contact.form__info_message
                )}
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
              />

              <input
                type="submit"
                value="SUBMIT"
                id="submit"
                className={contact.form__button}
                onClick={(e) => this.handleFormSubmit(e)}
              />
              <div className={contact.form__sent}>
                {this.state.mailSent && <div>Message Sent!</div>}
              </div>
            </form>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Contact
