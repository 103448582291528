import React from "react"
import classnames from "classnames"
import { StaticQuery, graphql } from "gatsby"

import formattedLogos from "../../utils/retrieveUrls"

import * as toolsUsed from "./toolsUsed.module.scss"

const Logos = ({ logos, tools, column }) => (
  <div
    className={classnames(toolsUsed.logos__container, {
      [toolsUsed.logos__container__column]: column,
    })}
  >
    {formattedLogos(logos, tools).map(elem => (
      <img
        src={elem.url}
        alt={elem.alt}
        className={toolsUsed.logos}
        key={elem.alt}
      />
    ))}
  </div>
)

const ToolsUsed = ({ tools = null, column = false }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulLogo {
          edges {
            node {
              title
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Logos logos={data.allContentfulLogo.edges} {...{ tools, column }} />
    )}
  />
)

export default ToolsUsed
