import React from "react"
import { StaticQuery, graphql } from "gatsby"

import * as header from "./header.module.scss"
import Container from "../Container"
import Row from "../Row"
import formattedLogos from "../../utils/retrieveUrls"
import ExternalLink from "../ExternalLink"

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulLogo {
          edges {
            node {
              title
              href
              image {
                file {
                  url
                }
              }
            }
          }
        }
        contentfulPerson(name: { eq: "Michael" }) {
          socials
        }
        contentfulAsset(title: { eq: "Headshot" }) {
          title
          file {
            url
          }
        }
      }
    `}
    render={data => {
      const { title, file } = data.contentfulAsset
      const logos = data.allContentfulLogo.edges
      const socials = data.contentfulPerson.socials

      return (
        <section className={header.section}>
          <Container>
            <Row className={header.row}>
              <img src={file.url} className={header.image} alt={title} />

              <div className={header.text__container}>
                <p className={header.text__name}>Michael Rae</p>
                <p className={header.text__career}>Web Developer</p>
              </div>

              <div className={header.socials__container}>
                {formattedLogos(logos, socials).map(elem => (
                  <ExternalLink
                    key={elem.alt}
                    className={header.socials__link}
                    href={elem.href}
                    label={elem.title}
                  >
                    <img src={elem.url} alt={elem.alt} height="15px" />
                  </ExternalLink>
                ))}
              </div>
            </Row>
          </Container>
        </section>
      )
    }}
  />
)

export default Header
