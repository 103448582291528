import React from "react"
import { useRollbar } from "@rollbar/react"

const ExternalLink = ({ children, className, disableLog, href, label }) => {
  const rollbar = useRollbar()

  const handleLog = () => {
    if (!disableLog) rollbar.log(`${label} opened`)
  }

  return (
    <a
      href={href}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleLog}
    >
      {children}
    </a>
  )
}

export default ExternalLink
