import React from "react"
import { graphql } from "gatsby"
import { Provider } from "@rollbar/react"

import "../styles/app.scss"
import SEO from "../components/seo"
import Header from "../components/Header/index"
import Skills from "../components/Skills/index"
import Contact from "../components/Contact/index"
import Logger from "../components/Logger"
import ProjectsList from "../components/ProjectsList"

const rollbarConfig = {
  accessToken: "b9037f642b0a443ea70ed0e00f4f2e65",
  environment: process.env.NODE_ENV,
}

const IndexPage = ({ data }) => {
  const projects = data.allContentfulProject.edges
  const filteredProjects = projects.filter(({ node }) => node.order > 0) // Must have an order > 0

  return (
    <Provider config={rollbarConfig}>
      <SEO
        title={"Home"}
        keywords={[
          "michael",
          "rae",
          "mobile",
          "al",
          "alabama",
          "developer",
          "javascript",
          "portfolio",
        ]}
      />
      <Header />
      <Logger />
      <ProjectsList projects={filteredProjects} />
      <Skills />
      <Contact />
    </Provider>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allContentfulProject(sort: { fields: [order], order: DESC }) {
      edges {
        node {
          title
          slug
          image {
            title
            file {
              url
            }
          }
          description {
            description
          }
          sourceCodeUrl
          buttonUrl
          tools
          raiseText
          order
          buttonEnabled
        }
      }
    }
  }
`
