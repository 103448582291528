import React from "react"
import classnames from "classnames"

import * as project from "./project.module.scss"
import Container from "../Container"
import Row from "../Row"
import ToolsUsed from "../ToolsUsed/index"
import { H2 } from "../Typography"
import ExternalLink from "../ExternalLink"

const Project = ({ leftProject, reducePadding, node }) => {
  const {
    buttonUrl,
    tools,
    sourceCodeUrl,
    title,
    description,
    image,
    raiseText,
    buttonEnabled,
  } = node

  return (
    <div
      className={classnames(
        project.background,
        { [project.background__left]: leftProject },
        { [project.background__reduce_padding]: reducePadding }
      )}
    >
      <Container>
        <Row className={project.row__primary} noMargin>
          <ExternalLink label={title} href={buttonEnabled ? buttonUrl : null}>
            <div
              className={classnames(project.image__container, {
                [project.image__container__left]: leftProject,
              })}
            >
              <img
                src={image ? image.file.url : ""}
                alt={image ? image.title : ""}
                className={project.image}
              />
            </div>
          </ExternalLink>

          <div
            className={classnames(
              project.text__container,
              { [project.text__container__left]: leftProject },
              { [project.text__container__raised]: raiseText }
            )}
          >
            <H2
              className={project.text__title}
              text={
                <ExternalLink
                  label={title}
                  href={sourceCodeUrl}
                  className={classnames(project.text__title_link, {
                    [project.text__title_link__active]: sourceCodeUrl,
                  })}
                >
                  {title}
                </ExternalLink>
              }
            />
            <div
              className={classnames(
                project.text__description,
                "remark-content"
              )}
              dangerouslySetInnerHTML={{
                __html: description ? description.description : "",
              }}
            />
          </div>
        </Row>
        <Row className={project.row__secondary} noMargin>
          <ToolsUsed tools={tools} />
          <ExternalLink
            label={title}
            href={buttonEnabled ? buttonUrl : null}
            className={classnames(project.button, {
              [project.button__active]: buttonEnabled,
            })}
          >
            {buttonEnabled ? "View Project" : "Launching Soon"}
          </ExternalLink>
        </Row>
      </Container>
    </div>
  )
}

export default Project
