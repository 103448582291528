import React, { useEffect } from "react"
import { useRollbar } from "@rollbar/react"

const Logger = () => {
  const rollbar = useRollbar()

  useEffect(() => {
    fetch("https://www.cloudflare.com/cdn-cgi/trace")
      .then(res => res.text())
      .then(data => {
        const jsonData = data
          .trim()
          .split("\n")
          .reduce(function(obj, pair) {
            pair = pair.split("=")
            return (obj[pair[0]] = pair[1]), obj
          }, {})

        logSiteOpened(jsonData.ip)
      })
      .catch(_error => {
        logSiteOpened()
      })
  }, [])

  const logSiteOpened = (ipv6 = null) => {
    rollbar.log("Site opened", {
      referrer: (document && document.referrer) || "none",
      ipv6: ipv6,
    })
  }

  return <span style={{ display: "none" }} />
}

export default Logger
